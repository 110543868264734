/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import SecondaryLayout from "../components/layout/secondary-layout";
import BlogCategoryListing from "../components/blog-category";
import BlogBreadCrumb from "../components/blogs/breadcrumb";
import { filterLanguage } from "../components/utils/language";
const BlogCategory = ({ data, pageContext }) => {
  const { nodes } = data.allContentfulBlog;
  const nodeslang = filterLanguage(false, nodes);
  const allBlogs = nodeslang;
  const blogCategory = data.contentfulBlogCategory;
  const { category } = pageContext;

  return (
    <SecondaryLayout location={false}>
      <SEO title={category} description={blogCategory.metaDescription} />
      <BlogBreadCrumb category={category} />
      <BlogCategoryListing allBlogs={allBlogs} />
    </SecondaryLayout>
  );
};

export const query = graphql`
  query allCategoryBlogsQuery($category: String!) {
    allContentfulBlog(
      filter: { category: { elemMatch: { title: { eq: $category } } } }
    ) {
      nodes {
        id
        node_locale
        title
        slug
        authorImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        authorName
        articlePublishDate
        featuredImage {
          file {
            url
          }
        }
        category {
          title
        }
      }
    }

    contentfulBlogCategory(title: { eq: $category }) {
      title
      metaDescription
    }
  }
`;

export default BlogCategory;
