/* eslint-disable react/no-array-index-key */
/* eslint-disable global-require */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import "./_blogCategory.scss";

const BlogCategoryListing = ({ allBlogs, finnish = false }) => {
  const [visiblePosts, setVisiblePosts] = useState(12);

  const loadMorePosts = () => {
    setVisiblePosts(visiblePosts + 3);
  };

  return (
    <section id="blog-category-listing-section">
      <div className="container">
        <div className="row">
          {allBlogs.slice(0, visiblePosts).map((post) => (
            <div
              className="col-md-4 mt-md-5 mt-4 animation-fade-in"
              key={post.id}
            >
              <Link
                className="blog-category-listing-feature-image"
                to={
                  !finnish
                    ? `/blogs/${post.slug.toLowerCase()}`
                    : `fi/blogs/${post.slug.toLowerCase()}`
                }
              >
                <img
                  className="img-fluid"
                  src={post.featuredImage.file.url}
                  alt={post.featuredImage.file.fileName}
                />
              </Link>
              <div className="row mt-3">
                <div className="col-12">
                  <ul className="blog-category-listing-category-tag">
                    {post.category.map((category, index) => (
                      <li key={index}>
                        <Link
                          to={
                            !finnish
                              ? `/blogs/${category.title
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`
                              : `fi/blogs/${category.title
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}`
                          }
                        >
                          {category.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <Link
                    className="blog-category-listing-blog-title"
                    to={
                      !finnish
                        ? `/blogs/${post.slug.toLowerCase()}`
                        : `fi/blogs/${post.slug.toLowerCase()}`
                    }
                  >
                    <h1>{post.title}</h1>
                  </Link>
                  <div className="blog-category-listing-author-wrap mt-lg-4 d-flex">
                    <Img
                      style={{
                        position: "relative",
                        height: "34px",
                        width: "34px",
                        borderRadius: "50%",
                      }}
                      fluid={post.authorImage.fluid}
                      alt="author"
                    />
                    <div className="blog-category-listing-author-name-and-publish ml-3">
                      <h5>{post.authorName}</h5>
                      <p>{post.articlePublishDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row blog-category-listing-load-more-wrap my-5 ">
          <div className="col-12 text-center">
            {visiblePosts < allBlogs.length && (
              <button
                onClick={loadMorePosts}
                className="btn blog-category-listing-load-more-btn"
                type="button"
              >
                Load more post{" "}
                <i className="fa fa-angle-down" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogCategoryListing;
